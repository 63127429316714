<style lang="scss" scoped>
.table {
  font-size: 0.7em;
}
.font-smaller {
  font-size: 0.8em;
}
.test {
  background-color: red !important;
}
.time-input {
  background-color: #fafafa;
}
</style>

<template>
  <div class="mt-3">
    <h4>Settings</h4>

    <b-form class="mt-5">
      <!-- StartWeekDay and Reminders-->
      <h4 class="text-left mt-5 mb-2">Week Options Setup</h4>
      <div class="text-left mt-3">
        <b-row no-gutters>
          <b-col cols="auto">
            <b-input-group size="sm" prepend="Week starts on ">
              <b-form-select
                v-model="settings.startWeekDay"
                :options="optionsStartDayWeekSelector"
                size="sm"
                class="w-auto text-left"
              ></b-form-select>
            </b-input-group>
          </b-col>
        </b-row>

        <b-row no-gutters class="mt-2">
          <b-col cols="auto">
            <b-input-group size="sm" prepend="Reminder on " append=" before week starts">
              <b-form-select
                v-model="settings.firstDayReminder"
                :options="optionsStartDayWeekSelector"
                size="sm"
                class="w-auto text-left"
              ></b-form-select>
            </b-input-group>
          </b-col>
        </b-row>
        <p>
          <small small class="text-muted">
            Don't forget to cilck on
            <span class="font-weight-bold">[Save]</span> after the changes
          </small>
        </p>
      </div>

      <h4 class="text-left mt-5 mb-2">Text Message Reminder Times</h4>

      <div class="text-left">
        <b-card no-body>
          <b-tabs pills card size="sm" v-model="indexTab" active-nav-item-class="bg-info">
            <b-tab title="Weekdays">
              <b-card-text>
                <h5>Reminders to be sent at:</h5>
                <b-form-tags
                  v-model="remindersTimes[indexTab]"
                  size="lg"
                  addTag="ononAddReminder"
                  separator=" "
                  placeholder="Enter new tags separated by space"
                  remove-on-delete
                  no-add-on-enter
                  class="mb-2"
                >
                  <template v-slot="{ tags, removeTag }">
                    <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                      <li v-for="tag in tags" :key="tag" class="list-inline-item mb-1">
                        <b-form-tag
                          @remove="removeTag(tag)"
                          :title="tag"
                          variant="success"
                        >{{ tag }}</b-form-tag>
                      </li>
                    </ul>
                  </template>
                </b-form-tags>
              </b-card-text>
            </b-tab>
            <b-tab title="Saturday">
              <b-card-text>
                <h5>Reminders to be sent at:</h5>
                <b-form-tags
                  v-model="remindersTimes[indexTab]"
                  size="lg"
                  addTag="ononAddReminder"
                  separator=" "
                  placeholder="Enter new tags separated by space"
                  remove-on-delete
                  no-add-on-enter
                  class="mb-2"
                >
                  <template v-slot="{ tags, removeTag }">
                    <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                      <li v-for="tag in tags" :key="tag" class="list-inline-item mb-1">
                        <b-form-tag
                          @remove="removeTag(tag)"
                          :title="tag"
                          variant="success"
                        >{{ tag }}</b-form-tag>
                      </li>
                    </ul>
                  </template>
                </b-form-tags>
              </b-card-text>
            </b-tab>
            <b-tab title="Sunday">
              <b-card-text>
                <h5>Reminders to be sent at:</h5>
                <b-form-tags
                  v-model="remindersTimes[indexTab]"
                  size="lg"
                  addTag="ononAddReminder"
                  separator=" "
                  placeholder="Enter new tags separated by space"
                  remove-on-delete
                  no-add-on-enter
                  class="mb-2"
                >
                  <template v-slot="{ tags, removeTag }">
                    <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                      <li v-for="tag in tags" :key="tag" class="list-inline-item mb-1">
                        <b-form-tag
                          @remove="removeTag(tag)"
                          :title="tag"
                          variant="success"
                        >{{ tag }}</b-form-tag>
                      </li>
                    </ul>
                  </template>
                </b-form-tags>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>

        <b-card class="mt-3 text-center time-input">
          <b-form-group label="Select times to add a reminder:">
            <div>
              <b-form-radio-group
                id="btn-radios-1"
                v-model="hours"
                :options="optionsHoursAM"
                buttons
                button-variant="outline-info"
                size="sm"
                name="radio-hours"
              ></b-form-radio-group>
            </div>
            <div>
              <b-form-radio-group
                id="btn-radios-2"
                v-model="hours"
                :options="optionsHoursPM"
                buttons
                button-variant="outline-info"
                size="sm"
                name="radio-hours"
              ></b-form-radio-group>
            </div>
            <div class="mt-2 text-center">
              <b-form-radio-group
                id="btn-radios-3"
                v-model="minutes"
                :options="optionsMinutes"
                buttons
                button-variant="outline-info"
                size="sm"
                name="radio-minutes"
              ></b-form-radio-group>
            </div>
            <h3 class="mt-3 mb-3">{{newReminderFormat}}</h3>
            <b-button variant="info" @click="onAddReminder()">Add Reminder</b-button>
          </b-form-group>
          <p>
            <small small class="text-muted">
              Don't forget to cilck on
              <span class="font-weight-bold">[Save]</span> after adding the times for the reminders
            </small>
          </p>
        </b-card>
      </div>

      <b-button class="mt-3 mb-3" @click="onSave()" variant="primary">Save</b-button>
    </b-form>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      settings: [],
      optionsStartDayWeekSelector: [
        { value: 0, text: "Sunday" },
        { value: 1, text: "Monday" },
        { value: 2, text: "Tuesday" },
        { value: 3, text: "Wednesday" },
        { value: 4, text: "Thursday" },
        { value: 5, text: "Friday" },
        { value: 6, text: "Saturday" }
      ],
      hours: "7",
      minutes: "00",
      remindersTimes: [
        ["7:30 am", "5:00 pm"],
        ["7:30 am", "5:00 pm"],
        ["7:30 am", "5:00 pm"]
      ],
      indexTab: 0,
      optionsHoursAM: [
        { text: "7", value: "7" },
        { text: "8", value: "8" },
        { text: "9", value: "9" },
        { text: "10", value: "10" },
        { text: "11", value: "11" },
        { text: "12", value: "12" }
      ],
      optionsHoursPM: [
        { text: "13", value: "13" },
        { text: "14", value: "14" },
        { text: "15", value: "15" },
        { text: "16", value: "16" },
        { text: "17", value: "17" },
        { text: "18", value: "18" },
        { text: "19", value: "19" },
        { text: "20", value: "20" },
        { text: "21", value: "21" }
      ],
      optionsMinutes: [
        { text: ":00", value: "00" },
        { text: ":15", value: "15" },
        { text: ":30", value: "30" },
        { text: ":45", value: "45" }
      ]
    };
  },
  computed: {
    newReminderFormat() {
      let d = moment({ hour: this.hours, minute: this.minutes });
      return d.format("h:mm a");
    }
  },
  mounted() {
    this.getSettings();
  },
  components: {},
  methods: {
    getSettings() {
      var url = "getSettings.php";
      var data = {};
      this.$http.post(url, data, { emulateJSON: true }).then(res => {
        this.settings = res.body.meals;
        this.settings.firstDayReminder = this.settings.firstDayReminder || "3";
        this.settings.timeReminder1 = this.settings.timeReminder1 || "10:00";
        this.settings.timeReminder2 = this.settings.timeReminder2 || "13:00";
        if (this.settings.remindersTimes) {
          this.remindersTimes = JSON.parse(this.settings.remindersTimes);
          if (this.remindersTimes.length == 2) {
            this.remindersTimes.push(["7:00 am", "5:00 pm"]);
          }
        }
      });
    },
    onAddReminder() {
      let d = moment({ hour: this.hours, minute: this.minutes });
      this.remindersTimes[this.indexTab].push(d.format("h:mm a"));
      this.remindersTimes[this.indexTab].sort(function(d1, d2) {
        let d1m = moment(d1, "h:mm a");
        let d2m = moment(d2, "h:mm a");
        let isAfter = 0;
        d1m.isAfter(d2m) ? (isAfter = 1) : (isAfter = -1);
        return isAfter;
      });
    },
    onSave() {
      var newSettings = {};
      newSettings = {
        startWeekDay: this.settings.startWeekDay,
        firstDayReminder: this.settings.firstDayReminder,
        timeReminder1: this.settings.timeReminder1,
        timeReminder2: this.settings.timeReminder2,
        remindersTimes: JSON.stringify(this.remindersTimes)
      };

      var url = "saveSettings.php";
      var data = { settings: newSettings };
      this.$http.post(url, data, { emulateJSON: true }).then(res => {
        this.$store.commit("updateStartWeekDay", res.body);
        this.$bvToast.toast(`Updated`, {
          title: "Settings",
          variant: "info",
          toaster: "b-toaster-top-center",
          autoHideDelay: 2000,
          appendToast: false
        });
      });
    }
  }
};
</script>
